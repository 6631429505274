import Hero from "./Hero/Hero";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import HomeTitle from "./HomeTitle";
import Honor from "./Honor/Honor"
import Question from "./Question/Question"
import Banner from "./Banner/Banner";
import End from "./End/End";

const HomeBox = styled((props) => (
    <Box component="section" {...props} />
))(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    [theme.breakpoints.up("md")]: {
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        maxWidth: "1100px",
    },
}));

const ChapterBox = styled((props) => (
    <Box component="section" {...props} />
))(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "8rem"
}));


export default function Home() {
    return (
        <HomeBox>
            <Banner />
            <Hero />
            <ChapterBox>
                <HomeTitle type="heading1" text="Fun Facts" />
                <Honor />
            </ChapterBox>
            <ChapterBox>
                <HomeTitle type="heading1" text="Questions for you" />
                <Question />
            </ChapterBox>
            <ChapterBox>
                <HomeTitle type="heading1" text="One more step, and you will become JJ's sugar momma" />
                <End />
            </ChapterBox>
        </HomeBox>
        
    );
};