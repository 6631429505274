import * as React from 'react';
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { Radio, FormLabel, Slider, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import "./Question.css"

const QuestionDiv = styled((props) => (
    <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={{
            offscreen: {
              y: 100
            },
            onscreen: {
              y: 0,
              transition: {
                type: "spring",
                bounce: 0.4,
                duration: 0.6
              }
            }
          }}
        {...props}
    />
))(({ theme }) => ({
}));

export default function Question() {
    const [cookWarning, setCookWarning] = React.useState('  ');
    const [driveWarning, setDriveWarning] = React.useState('  ');
    const [cleanWarning, setCleanWarning] = React.useState('  ');
    const [lastWarning, setLastWarning] = React.useState('  ');
    const handleCookChange = (event) => {
        if (event.target.value === 'yes') {
            setCookWarning('Oh noooo, he can\'t cook :(');
        } else {
            setCookWarning('YAYYYY');
        }
    }

    const handleDriveChange = (event) => {
        if (event.target.value === 'yes') {
            setDriveWarning('He will be a good personal driver!');
        } else {
            setDriveWarning('He loves driving tho');
        }
    }

    const handleCleanChange = (event) => {
        if (event.target.value === 'yes') {
            setCleanWarning('Hmmm if so I guess you need to pay more.');
        } else {
            setCleanWarning('Noice! He\'s lazy to do cleaning lol');
        }
    }

    const handleSliderChange = (e, newValue) => {
        if (typeof newValue === 'number') {
            if (newValue === 0) {
                setLastWarning('Are you sure?');
            } else if (newValue <= 4) {
                setLastWarning('He will try his best!');
            } else if (newValue <= 10) {
                setLastWarning('You are crazy!');
            } else {
                setLastWarning('You need to see a doctor.')
            }
        }
    }
    return (
        <div className="question-container">
            <QuestionDiv className="question-box">
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">&#127836; Do you need him to cook?</FormLabel>
                    <RadioGroup
                        row
                        name="controlled-radio-buttons-group"
                        onChange={handleCookChange}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
                <p className='question-warning'>{cookWarning}</p>
            </QuestionDiv>

            <QuestionDiv className="question-box">
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">&#128665; Do you need him to drive?</FormLabel>
                        <RadioGroup
                            row
                            name="controlled-radio-buttons-group"
                            onChange={handleDriveChange}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    <p className='question-warning'>{driveWarning}</p>
            </QuestionDiv>

            <QuestionDiv className="question-box">
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">&#128701; Do you need him to clean your place?</FormLabel>
                    <RadioGroup
                        row
                        name="controlled-radio-buttons-group"
                        onChange={handleCleanChange}
                    >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
                <p className='question-warning'>{cleanWarning}</p>
            </QuestionDiv>

            <QuestionDiv className="question-box">
                <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">&#128286; How many times are you going to *** everyday?</FormLabel>
                    <Slider
                        color="success"
                        defaultValue={0}
                        aria-label="slider"
                        valueLabelDisplay="auto"
                        onChange={handleSliderChange}
                        max={20}
                    />
                </FormControl>
                <p className='question-warning'>{lastWarning}</p>
            </QuestionDiv>
        </div>
    )
}