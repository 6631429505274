import './App.css';
import Home from './Components/Home/Home';    

import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import loadable from '@loadable/component';

const Footer = loadable(() => import('./Components/Footer/Footer'));



export default class App extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div id="app-div">
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
          <Footer />
        </BrowserRouter>

      </div>
  );
  }
}
