import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import pic from '../../../Assets/JJ.png';
import './Banner.css'

const titleVariants = {
    offscreen: {
      y: 100
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.6,
        delay: 0.5,
      }
    }
  };

const cardVariants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 50,
      rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
        delay: 0.8,
      }
    }
  };

const SelfBox = styled((props) => (
    <Box component="section" {...props} />
))(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
}));


export default function Banner() {
    return (
        <SelfBox>
            <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: false, amount: 0.8 }}
                variants={titleVariants}
            >
                <h1 className="banner-title">Find JJ a SUGAR MOMMA!</h1>
            </motion.div>
            <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: false, amount: 0.8 }}
                >
                <div className="splash" />
                <motion.div className="card" variants={cardVariants}>
                    <img src={pic} className="card-pic"></img>
                </motion.div>
            </motion.div>
        </SelfBox>
    )
}