import * as React from 'react';
import { Box, Grid, Typography, Button, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import data from "./HonorData.json";
import { motion } from "framer-motion";
import { Fragment } from "react";
import "./Honor.css";
import dogImg from '../../../Assets/dogs.jpeg';
import tallImg from '../../../Assets/tall.jpg';
import vallyballImg from '../../../Assets/valleyball.jpeg';

const HonorGrid = styled((props) => (
    <Grid
        component={motion.ul}
        container
        columnSpacing={{ xs: "6vw", sm: "2.5vw", md: "3vw", lg: "6vw" }}
        rowSpacing={1}
        {...props}
    />
))(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: 0,
}));

const imgMap = {
    dog: dogImg,
    tall: tallImg,
    vallyball: vallyballImg,
}

const HonorItem = styled((props) => (
    <Grid
        component={motion.li}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={{
            visible: (i) => ({ opacity: 1, scale: 1, transition: { type: "spring", delay: 0.2 * i } }),
            hidden: { opacity: 0, scale: 0 }
        }}
        item
        xs={4}
        sm={3}
        {...props}
    />
))(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "default",
}));

const HonorText = styled(Box)(({ theme }) => ({
    fontSize: "3rem",
    lineHeight: 1.25,
    textTransform: "lowercase",
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  };

export default function Honor(props) {
    const [open, setOpen] = React.useState(false);
    const [modalInfo, setModalInfo] = React.useState({});
    const handleOpen = (title, desc) => {
        setOpen(true);
        setModalInfo({
            title,
            pic: imgMap[title],
            desc,
        })
    }
    const handleClose = () => setOpen(false);
    return (
        <Fragment>
            <HonorGrid>
                {data.map(({ title, emoji, desc }, idx) => (
                    <HonorItem key={title} custom={idx}>
                        <Button
                            onClick={() => handleOpen(title, desc)}
                            variant="contained"
                            style={{
                                backgroundColor: '#2a2a2a',
                                border: '2px solid grey',
                                borderRadius: "5px",
                                boxShadow: "5px 5px 5px #2a2a2a",
                            }}
                        >
                            <HonorText>
                                {emoji}
                            </HonorText>
                        </Button>
                        
                    </HonorItem>
                ))}
            </HonorGrid>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" className="modal-desc" component="h2" mt="10">
                    {modalInfo.desc}
                    </Typography>
                    <div className="img-box"><img src={modalInfo.pic}></img></div>
                </Box>
            </Modal>
        </Fragment>
    );
};