import * as React from 'react';
import {TextField, Button, Box, Modal, Typography} from "@mui/material";
import "./End.css";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 200,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
  };

export default function End() {
    const [open, setOpen] = React.useState(false);
    const [val, setVal] = React.useState(0);
    const [warning, setWarning] = React.useState('???');
    const handleChange = (event) => {
        setVal(event.target.value);
        
    }
    const handleConfirm = () => {
        const number = parseInt(val);
        if (number > 2000) {
            setWarning("Sounds good! Follow his Intagram <a href=\"https://www.instagram.com/itsonlyjj/\">@itsonlyJJ</a> and dm him!");
        } else if (number > 1000) {
            setWarning("Come on. Give him more.");
        } else {
            setWarning("You must be kidding.");
        }
        setOpen(true);
    }
    const handleClose = () => setOpen(false);
    return (
        <div className="end-container">
            <p className="end-question">So... How much would you pay him per month?</p>
            <TextField
                color="success"
                id="Amount-text-area"
                label="$$$"
                variant="outlined"
                type="number"
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={handleChange}
            />
            <div className="end-btn">
                <Button onClick={handleConfirm} color="success" variant="contained">Confirm</Button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" className="modal-desc" component="h2" mt="10">
                        <p dangerouslySetInnerHTML={{__html: warning}} />
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}